$funkita: (
  'color--error': #c70202,
  'color--primary': #ff5a5a,
  'color--secondary': #ffecee,
  'color--active': #f7b5c4,
  'color__text--primary': #fff,
  'color__text--secondary': #000,
  'color__text--active': #fff,
  'color__link': #000,
  'color__link--active': #ff5a5a,
  'color--gray': #f3f1f1,
  'color--gray--dark': #8f8d8d,
  'color--gray-light': #ececec,
  'color--red': #db0000,
  'color--dark-red': #c70e0e,
  'color--black': #000,
  'color--gray--mui-step': rgba(0, 0, 0, 0.6),
  'body__background': #fff,
  'color__icon--gray': #c4c4c4,
  'snackbar__background--success': #91ebe2,
  'snackbar__background--error': #ff8891,
  'snackbar__background--info': #3e7ae9,
  'snackbar__background--warning': #ffbb00,
  'snackbar__text--info': #fff,
  'snackbar__text': #000,
  'shop-switcher__background': #000,
  'shop-switcher__text': #fff,
  'divider__color': #666,
  'border__color': #ddd,
  'color__text--disabled': #8d8d8d,
  'header__background': #ff5a5a,
  'header-logo__text': #000,
  'header-logo__text--opposite': #fff,
  'header-logo__border--bottom--opposite': #000000,
  'header-top-message__background': #fdebeb,
  'header-message__text': #fff,
  'header__icon': #5b5250,
  'header__border--bottom': darken(#ff5a5a, 10%),
  'auth-menu__link--border': #333,
  'auth-menu__background': #eeeef0,
  'auth-menu__border': #e7e9e8,
  'mega-menu__background': #fafafa,
  'size-guide-mobile-tabs__border': #ececec,
  'cms-header__background': #fdebeb,
  'catalog-item__border': #fdfdfd,
  'catalog-item__info': #000,
  'catalog-item1__background': #ff8891,
  'catalog-item2__background': #fff08b,
  'catalog-item3__background': #ffebed,
  'catalog-item4__background': #91ebe2,
  'product-filters__background': #ededed,
  'product-filters__border': #ddd,
  'product-filters__modal__backdrop__background': rgba(0, 0, 0, 0.7),
  'product-filters__modal__cart__background': #fff,
  'catalog-view-switch__track': #ededed,
  'catalog-view-switch__thumb': #fff,
  'modal-button__background--active': #ff5a5a,
  'modal-button__background--inactive': #ededed,
  'modal-button__text--active': #fff,
  'modal-button__text--inactive': #000,
  'product-promotion-header__background': #ffecee,
  'mega-menu__background--level1': #ffecee,
  'mega-menu__background--level1--hover': #f7b5c4,
  'mega-menu__background--level1--active': #ff5a5a,
  'mega-menu__text--level1': #000,
  'mega-menu__text--level1--hover': #fff,
  'mega-menu__text--level1--active': #fff,
  'footer-block__line': #ececec,
  'side-image__background': url('/public/img/side_img_funkita.jpg'),
  'mini-cart__background': #fff,
  'mini-cart__border': #ddd,
  'sidebar__background': #fff,
  'sidebar-tab__border': #ccc,
  'sidebar-tab__border--inactive': #eee,
  'map-marker-user__color--default': #005aff,
  'map-marker__color--default': lighten(#ff4646, 5%),
  'map-marker__color--active': darken(#ff4646, 15%),
  'text-tile-line__color': #fff,
  'text-tile__background': #ff5a5a,
  'blog-post__color--secondary': #666,
  'mui-button__background--info': #e0e0e0,
  'mui-button-text__background--info-hover': rgba(0, 0, 0, 0.04),
  'up-sell__modal__backdrop__background': rgba(0, 0, 0, 0.7),
  'up-sell__modal__cart__background': #fff,
  'up-sell-products__background': #fff,
  'recommend-channel__box-shadow': 0 1px 2px rgba(0, 0, 0, 0.5),
  'recommend-channel__background-color': #fff,
  // static pages
  'sp-heading__background': #fdebeb,
  'catalog-item-feature__background': rgba(0, 0, 0, 0.2),
  'catalog-item-event__background': #fff,
  'catalog-item-event__color': #ff5a5a,
  'catalog-item-event__box-shadow': 0 1px 2px rgba(0, 0, 0, 0.5),
  'product-gallery-event__background': #fff,
  'product-gallery-event__color': #ff5a5a,
  'product-gallery-event__box-shadow': 0 1px 2px rgba(0, 0, 0, 0.5)
);

:export {
  @each $key, $val in $funkita {
    #{$key}: $val;
  }
}
